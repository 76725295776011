<script>
  import { vectorSource } from '../../mixins'

  export default {
    name: 'VlSourceVector',
    mixins: [
      vectorSource,
    ],
  }
</script>
