//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { feature } from '../../mixins'
  import PointGeom from './point-geom.vue'

  /**
   * A vector object for geographic features with a geometry and other attribute properties,
   * similar to the features in vector file formats like **GeoJSON**.
   */
  export default {
    name: 'VlFeature',
    components: {
      PointGeom,
    },
    mixins: [
      feature,
    ],
  }
