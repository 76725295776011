import { InternalGraphQLAPIClass } from '@aws-amplify/api-graphql/internals';
import { Amplify, Cache, ConsoleLogger } from '@aws-amplify/core';
import { ApiAction, Category, } from '@aws-amplify/core/internals/utils';
/**
 * NOTE!
 *
 * This is used only by DataStore.
 *
 * This can probably be pruned and/or removed. Just leaving it as much of the same
 * state as possible for V6 to reduce number of potentially impactful changes to DataStore.
 */
const logger = new ConsoleLogger('API');
/**
 * @deprecated
 * Use RestApi or GraphQLAPI to reduce your application bundle size
 * Export Cloud Logic APIs
 */
export class InternalAPIClass {
    /**
     * Initialize API
     */
    constructor() {
        this.Cache = Cache;
        this._graphqlApi = new InternalGraphQLAPIClass();
    }
    getModuleName() {
        return 'InternalAPI';
    }
    /**
     * to get the operation type
     * @param operation
     */
    getGraphqlOperationType(operation) {
        return this._graphqlApi.getGraphqlOperationType(operation);
    }
    graphql(options, additionalHeaders, customUserAgentDetails) {
        const apiUserAgentDetails = {
            category: Category.API,
            action: ApiAction.GraphQl,
            ...customUserAgentDetails,
        };
        return this._graphqlApi.graphql(Amplify, options, additionalHeaders, apiUserAgentDetails);
    }
}
export const InternalAPI = new InternalAPIClass();
