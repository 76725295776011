<script>
  import { xyzSource } from '../../mixins'

  export default {
    name: 'VlSourceXyz',
    mixins: [
      xyzSource,
    ],
  }
</script>
